import React, { Component } from "react"
import { Helmet } from "react-helmet"
import { ContactForm, Layout } from "../../../components"

import {
  HalfWidthBlock,
  Medailon,
  PaddingWrapper,
  ReferenceContainer,
  TitleH2,
} from "@igloonet-web/shared-ui"
import Logo from "../../../images/reference/fsivut/logo.png"
import Banner from "../../../images/reference/fsivut/google-seznam-banner.png"
import ReferenceBanner from "@igloonet-web/shared-ui/components/reference/reference-banner"
import ReferenceBannerContent from "@igloonet-web/shared-ui/components/reference/reference-banner-content"
import Site from "../../../images/reference/fsivut/socsite.jpg"
import Site2 from "../../../images/reference/fsivut/socsite02.png"
import Katerina from "../../../images/reference/fsivut/katerina.jpg"
import Top from "../../../images/reference/fsivut/top.png"

class Fsivut extends Component {
  constructor({ props }) {
    super(props)
  }

  render() {
    const pozadi = {
      backgroundImage: `url(${Top})`,
    }

    return (
      <Layout>
        <Helmet>
          <title>Náborové kampaně pro FSI VUT | igloonet.cz</title>
          <meta
            name="description"
            content="„igloonet je náš lety prověřený partner, na kterého se můžeme při realizaci on-line náborových kampaní 100% spolehnout,“
            Mgr. Kateřina Růžičková, FSI VUT."
          />
        </Helmet>
        <ReferenceBanner
          styles={pozadi}
          logo={Logo}
          extodkaz="//fme.vutbr.cz/"
          heading="Dlouhodobá spolupráce na náborových kampaních studentů"
          odstavec="jaro 2012 až současnost"
        >
          <ReferenceBannerContent
            mainText="Fakulta strojního inženýrství se pyšní nejen titulem „nejvyšší vysoká” – její budova má 74 metrů –, ale také největším počtem studentů ze všech strojáren v republice – fakultu jich navštěvuje přes 4 200."
            secondaryText="Od roku 2012 se společně snažíme toto číslo zvyšovat, jak kampaněmi napříč Českem a Slovenskem, tak celosvětovými kampaněmi na nábor zahraničních studentů. Těší nás, že každý rok studium dokončí přibližně 1000 absolventů, kteří nemají problémy s uplatněním."
          />
        </ReferenceBanner>

        <ReferenceContainer>
          <PaddingWrapper>
            <TitleH2>Cíle spolupráce</TitleH2>
            <HalfWidthBlock left>
              <ul>
                <li>
                  Udržet a navýšit počet přihlášek i přes nepříznivé
                  demografické vyhlídky
                </li>
                <li>
                  Zvýšit povědomí o FSI jako přední české strojírenské fakultě
                  mezi maturanty
                </li>
              </ul>
            </HalfWidthBlock>
          </PaddingWrapper>

          <PaddingWrapper>
            <TitleH2>Google a Seznam</TitleH2>
            <p>
              Přestože jde primárně o e-commerce kanály, využíváme naplno sílu
              dvou hlavních vyhledávačů také při náborových kampaních. Cílíme v
              nich jak na konkrétní, brandové vyhledávací dotazy, tak přímo
              oborové. Vzhledem k techničtějšímu zaměření oborů volíme rozdílné
              bidovací strategie na muže a ženy a pohlaví cílové skupiny
              přizpůsobujeme i textace.
            </p>
            <p>
              Využíváme také reklamy v obsahové síti, kde se na relevantních
              webech snažíme získat pozornost naší cílovky. Součástí kampaně je
              také podpora offline aktivit fakulty souvisejících s akvizicí
              nových studentů, třeba Dnů otevřených dveří.
            </p>
            <img src={Banner} alt="" className="img-fluid my-5" />
          </PaddingWrapper>
          <PaddingWrapper>
            <TitleH2>Sociální sítě</TitleH2>
            <p>
              Hlavním kanálem pro zvýšení povědomí o Fakultě strojního
              inženýrství mezi maturanty je Facebook, v posledních letech
              doplněný o Instagram. Na základě demografických dat, kterými o
              uživatelích tyto sociální sítě disponují, dokážeme najít přesnou
              cílovou skupinu doplněnou také publiky podobnými dosavadním
              zájemcům a studentům. Potenciální uchazeče oslovujeme napříč
              zařízeními i reklamními platformami.
            </p>
            <p>
              Facebook využíváme také jako remarketingový nástroj. Pomocí
              měřících kódů umíme oslovit uchazeče v různých fázích procesu
              rozhodování až po podání samotné přihlášky. K remarketingu
              využíváme také dynamickou propagaci oborů skrze produktový
              katalog, který je běžný u e-commerce klientů. Zboží zde však
              nahradily studijní obory, které uživatelům ukazujeme pár dní po
              zobrazení webu.
            </p>
            <img src={Site} alt="" className="img-fluid my-5" />
            <p>
              Narozdíl od Googlu a Seznamu, kteří člověka identifikuji na
              základě jeho cookies v prohlížeči, má Facebook tu výhodu, že
              rozpoznává napříč zařízeními. Dokáže konkrétního uživatele najít
              na mobilu, počítači i tabletu, a my tak doměříme třeba vliv
              reklamy na Instagramu, i když si student podá přihlášku přes
              Google.
            </p>
            <img src={Site2} alt="" className="img-fluid my-5" />
          </PaddingWrapper>
          <PaddingWrapper>
            <TitleH2>Analytika a návrh webu</TitleH2>
            <p>
              Kampaň, jejíž zásah nedokážete změřit, je špatná kampaň. Správně
              nasazenou analytiku považujeme za polovinu úspěchu, proto (s
              pomocí rodu{" "}
              <a
                href="//houseofrezac.com"
                target="blank"
                title="House of Řezáč"
              >
                House of Řezáč
              </a>
              ), nasazujeme měřící kódy tak, abychom mohli remarketovat
              uživatele napříč univerzitními weby i v jednotlivých krocích
              přihláškového formuláře a segmentovat je do příslušných publik.
              Zároveň vylučujeme z kampaní stávající studenty i zaměstnance,
              které reklama na vlastní alma mater nemusí zrovna zajímat.
            </p>
            <p>
              Mluvit se snažíme i do podoby webu, podíleli jsme se na
              <a href="//studujstrojarnu.cz" target="blank">
                {" "}
                návrhu stránek
              </a>
              , který poté fakulta sama realizovala.
            </p>
          </PaddingWrapper>
          <PaddingWrapper>
            <TitleH2>Zahraniční kampaň</TitleH2>
            <p>
              V roce 2017 jsme se popasovali s výzvou zvýšit počet přihlášek na
              zahraniční studijní programy fakulty. Hlavním kanálem pro
              seznámení evropských studentů s FSI se stal Facebook, který nám
              umožnil najít věkově i zájmově podobné uživatele stávajícím
              studentům.
            </p>
            <p>
              Vzhledem k omezenému rozpočtu jsme si nemohli dovolit zacílit na
              každý stát zvlášť, proto jsme se rozhodli rozdělit publika na
              západ, východ, sever a střed Evropy. Po krátkém testování jsme
              navíc vybrali TOP státy, mezi které jsme alokovali větší část
              rozpočtu.
            </p>
          </PaddingWrapper>
          <Medailon
            image={Katerina}
            alt=""
            text="igloonet je náš lety prověřený partner, na kterého se můžeme při realizaci on-line náborových kampaní
          100% spolehnout. Dlouhodobá spolupráce umožňuje navázat na osvědčené prvky z přecházejících kampaní, ale
          igloonet každý rok přichází také s marketingovými novinkami. Kampaně hodnotíme velmi pozitivně, fakultě se
          daří udržet optimální počet studentů bakalářského studia."
            name="Mgr. Kateřina Růžičková"
            position="marketingová manažerka"
          />
          <PaddingWrapper>
            <TitleH2>Shrnutí</TitleH2>
            <HalfWidthBlock>
              <p>
                Spolupráce s Fakultou strojního inženýrství je pro nás ukázkou
                ideálního dlouholetého partnerství. Každý rok implementujeme
                novinky a posouváme kampaně dál. I přes narůstající konkurenci
                (marketingovou i akademickou) se nám daří počet přihlášených
                studentů držet.
              </p>
            </HalfWidthBlock>
          </PaddingWrapper>
        </ReferenceContainer>

        <ContactForm contact="adam" />
      </Layout>
    )
  }
}

export default Fsivut
